import { reactive } from 'vue';
import { forEach } from '@xsys2/functions';
import animationProps from './animationProps';

export default function animationController() {
  const props = reactive({});
  let currentPropsIndex = 0;

  function applyProps(newProps) {
    forEach(newProps, (key, value) => {
      props[key] = value;
    });
  }

  function next() {
    applyProps(animationProps[currentPropsIndex++]);
  }

  // function prev() {
  //   applyProps(animationProps[--currentPropsIndex]);
  // }

  next();
  setTimeout(next, 2000); // scale
  setTimeout(next, 3500); // squeeze
  setTimeout(next, 4000); // expand

  return {
    props,
  };
}
