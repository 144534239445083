<template>
  <div
    data-vue-component-name="PreviewWebinars"
    :class="{ 'hovered': !infoMode && isHovered }"
    :style="{ 'cursor': (infoMode && 'default') || 'pointer' }"
    ref="preview"
  >
    <AppTooltip
      :additional-condition="infoMode"
      :message="message"
      :style="{ cursor: infoMode && 'pointer' }"
    >
      <h2>webinars</h2>

      <transition name="fade">
        <IconInfo v-if="infoMode" />
      </transition>
    </AppTooltip>

    <img
      src="@/assets/images/previews/webinars.png"
      alt="webinars background"
      :class="{ 'info-mode': infoMode }"
    >
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { IconInfo } from '@xsys2/components';
import { listenHover } from '@/composables';

defineProps({
  infoMode: {
    type: Boolean,
    default: false,
  },
});

const preview = ref(null);
const { isHovered } = listenHover(preview);

const message = 'Hac tortor, sit eget diam pretium. In elit enim, malesuada'
                + 'lectus faucibus a nam. Volutpat ultrices tristique tortor'
                + 'morbi. Et pulvinar quis lorem ac etiam nunc.';
</script>

<style scoped lang="scss">
@import "src/assets/styles/pages/home/preview.scss";

[data-vue-component-name="PreviewWebinars"] {
  transform-origin: bottom;
  width: 16.6%;
  @include wrapperStyles;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all .25s ease-in-out 0s;
  }

  [data-vue-component-name="AppTooltip"] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.6%;

    h2 {
      text-align: center;
      @include labelStyles;
    }

    svg {
      position: absolute;
      top: -0.2vw;
      right: 2.5vw;
      width: 1.9vw;
      height: 1.9vw;
      padding: 0.4vw;
    }
  }
}
</style>
