import { ref, onMounted, onBeforeUnmount } from 'vue';

export default function listenHover(node) {
  const isHovered = ref(false);

  onMounted(() => {
    node.value.addEventListener('mouseenter', onMouseEnter);
    node.value.addEventListener('mouseleave', onMouseLeave);
  });

  onBeforeUnmount(() => {
    node.value.removeEventListener('mouseenter', onMouseEnter);
    node.value.removeEventListener('mouseleave', onMouseLeave);
  });

  function onMouseEnter() {
    isHovered.value = true;
  }
  function onMouseLeave() {
    isHovered.value = false;
  }

  return { isHovered };
}
