<template>
  <div
    data-vue-component-name="PreviewEvents"
    :class="{ 'hovered': !infoMode && isHovered }"
    :style="{ 'cursor': (infoMode && 'default') || 'pointer' }"
    ref="preview"
  >
    <section>
      <img
        v-for="(banner, bannerName) in banners"
        :key="bannerName"
        :id="bannerName"
        :src="banner.url"
        :alt="bannerName"
        :class="{ 'info-mode': infoMode }"
        @error="selfDestroy"
      />
    </section>

    <AppTooltip
      :additional-condition="infoMode"
      :message="message"
      :style="{ cursor: infoMode && 'pointer' }"
    >
      <div>
        <h2>Events</h2>

        <transition name="fade">
          <IconInfo v-if="infoMode" />
        </transition>
      </div>
    </AppTooltip>

    <img
      src="@/assets/images/previews/events.png"
      alt="events background"
      :class="{ 'info-mode': infoMode }"
    >
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IconInfo } from '@xsys2/components';
import { listenHover } from '@/composables';

defineProps({
  infoMode: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const preview = ref(null);
const { isHovered } = listenHover(preview);

const message = 'Hac tortor, sit eget diam pretium. In elit enim, malesuada'
  + 'lectus faucibus a nam. Volutpat ultrices tristique tortor'
  + 'morbi. Et pulvinar quis lorem ac etiam nunc.';

const banners = computed(() => store.state.home.bannersForEvents);
const selfDestroy = ({ target }) => target.remove();
</script>

<style scoped lang="scss">
@import "src/assets/styles/pages/home/preview.scss";

[data-vue-component-name="PreviewEvents"] {
  transform-origin: bottom right;
  width: 30.75%;
  height: 36.5%;
  margin: 0;
  @include wrapperStyles;

  section {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    perspective: 950px;

    img {
      pointer-events: none;
      position: absolute;
      transition: all .25s ease-in-out 0s;

      &#leftBanner {
        width: 11.7%;
        height: 27%;
        top: 28%;
        left: 10.1%;
      }

      &#topBanner {
        width: 35.7%;
        height: 16.7%;
        top: 9%;
        left: 32.5%;
      }

      &#rightBanner {
        width: 11.6%;
        height: 41%;
        top: 20.7%;
        left: 75%;
        transform: rotateY(-44deg) skew(0, 3deg);
        transform-origin: bottom right;
      }
    }
  }

  img {
    @include absolute-full;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
    transition: all .25s ease-in-out 0s;
  }

  [data-vue-component-name="AppTooltip"] {
    position: absolute;
    top: 13vw;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    z-index: 1;

    h2 {
      text-align: center;
      @include labelStyles;
    }

    svg {
      position: absolute;
      top: -0.3vw;
      left: 100%;
      width: 1.9vw;
      height: 1.9vw;
      padding: 0.4vw;
    }
  }

}
</style>
