import { ref, watch } from 'vue';

function getLocalStorageValue(key) {
  return localStorage.getItem(key);
}
function setLocalStorageValue(key, value) {
  return localStorage.setItem(key, value);
}

const KEY_NAME = 'isTutorialCompleted';
const isTutorialCompleted = ref(getLocalStorageValue(KEY_NAME));
watch(isTutorialCompleted, value => setLocalStorageValue(KEY_NAME, value));

export default isTutorialCompleted;
