import { recursiveCamelCase, forEach, has } from '@xsys2/functions';

export default {
  SET_DATA(state, payload) {
    forEach(recursiveCamelCase(payload), (key, value) => {
      if (has(state, key)) {
        state[key] = value;
      }
    });
  },
};
