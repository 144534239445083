<template>
  <div data-vue-component-name="PreviewController">
    <TheInfoButton v-model="isInfoVisible" />

    <PreviewPrintSamples
      v-bind="props.PreviewPrintSamples"
      @click="goTo({ name: 'PrintSamples' })"
    />

    <PreviewEvents
      v-bind="props.PreviewEvents"
      @click="goTo({ name: 'Events' })"
    />

    <PreviewTechnicalRoom
      v-bind="props.PreviewTechnicalRoom"
      @click="goTo({ name: 'TechnicalRoom' })"
    />

    <PreviewXsysTv
      v-bind="props.PreviewXsysTv"
      @click="goTo({ name: 'XsysTV' })"
    />

    <PreviewWebinars
      v-bind="props.PreviewWebinars"
      @click="goTo({ name: 'Webinars' })"
    />

    <template v-if="!isTutorialCompleted">
      <AppLogo v-bind="props.AppLogo" />

      <!-- Welcome -->
      <div id="welcome" v-bind="props.Welcome">
        <h2>Welcome to the XSYS Virtual Platform</h2>
        <p>I will quickly tell you about the main sections of our site</p>
      </div>

      <!-- ButtonsContainer -->
      <div
        v-for="i in 2"
        :key="i"
        :id="`button-container${i}`"
        v-bind="props[`ButtonsContainer${i}`]"
      >
        <AppButton
          pill
          type="outline"
          radius="64px"
          @click="skip"
        >
          <span>Skip Tutorial</span>
        </AppButton>

        <AppButton
          pill
          type="fill"
          background="primary"
          radius="64px"
          @click="next"
        >
          <span>Next</span>
          <IconArrow />
        </AppButton>
      </div>

      <!-- Button start -->
      <AppButton
        id="button-start"
        v-bind="props.ButtonStart"
        pill
        type="fill"
        background="primary"
        radius="64px"
        @click="skip"
      >
        <span>Start</span>
        <IconArrow />
      </AppButton>

      <div id="triangle" v-bind="props.Triangle" />
      <AppImage v-bind="props.Person1" alt="woman image 1" />
      <AppImage v-bind="props.Person2" alt="woman image 2" />
      <PreviewPointer v-bind="props.PreviewPointer1" />
      <PreviewPointer v-bind="props.PreviewPointer2" />
      <PreviewDescription v-bind="props.PreviewDescription1" />
      <PreviewDescription v-bind="props.PreviewDescription2" />
    </template>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import {
  AppLogo,
  IconArrow,
  AppImage,
  AppButton,
} from '@xsys2/components';
import {
  infoController,
  tutorialController,
  isTutorialCompleted,
} from '@/modules/tutorial';

const { props, next, skip } = tutorialController();
const { isInfoVisible } = infoController(props);

const router = useRouter();
const goTo = (route) => {
  if (!isInfoVisible.value) {
    router.push(route);
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="PreviewController"] {
  [data-vue-component-name="TheInfoButton"] {
    position: absolute;
    right: 180px;
    top: 32px;
    top: 2vw;
    right: 10vw;
    z-index: 3;
  }

  [data-vue-component-name="PreviewPrintSamples"] {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 20;
  }

  [data-vue-component-name="PreviewEvents"] {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 0;
  }

  [data-vue-component-name="PreviewTechnicalRoom"] {
    position: absolute;
    z-index: 10;
    top: 60%;
    left: 29.4%;
  }

  [data-vue-component-name="PreviewXsysTv"] {
    position: absolute;
    z-index: 2;
    top: 45.9%;
    left: 19.4%;
  }

  [data-vue-component-name="PreviewWebinars"] {
    position: absolute;
    z-index: 2;
    top: 45.1%;
    right: 18.8%;
  }

  // TUTORIAL
  [data-vue-component-name="AppLogo"] {
    z-index: 70;
    height: auto;
  }

  #welcome {
    z-index: 70;
    text-align: center;

    h2 {
      width: 21vw;
      margin: 0 auto;
      font-size: 1.6vw;
      font-weight: 800;
      letter-spacing: 0.1em;
    }

    p {
      margin-top: 6%;
      font-size: 1vw;
      opacity: 0.6;
    }
  }

  #button-container1,
  #button-container2 {
    z-index: 70;
    width: 25vw;
    @include flex-center;

    [data-vue-component-name="AppButton"] {
      padding: 1.094vw 0;
      width: 100%;
      font-size: 1vw;
      @include spaceX(1.250vw);

      [data-vue-component-name="IconArrow"] {
        margin-left: 0.833vw;
      }
    }
  }

  #button-start {
    z-index: 70;
    width: 11.875vw;
    padding: 1.094vw 0;
    font-size: 1vw;

    [data-vue-component-name="IconArrow"] {
      margin-left: 0.833vw;
    }
  }

  #triangle {
    z-index: 70;
    border-left: 18.75vw solid transparent; // height
    border-bottom: 11.25vw solid map-get($theme-colors, 'primary'); // width
  }

  [data-vue-component-name="AppImage"] {
    z-index: 80;
    pointer-events: none;
    object-fit: contain;
    height: auto;
  }

  [data-vue-component-name="PreviewPointer"] {
    z-index: 70;
  }

  [data-vue-component-name="PreviewDescription"] {
    width: 22.51%;
    z-index: 70;
  }
}

@media screen and (max-width: 768px) {
  [data-vue-component-name="PreviewController"] {
    [data-vue-component-name="TheInfoButton"] {
      right: 180px;
      top: 32px;
    }
  }
}
</style>
