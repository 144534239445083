import { shallowRef } from 'vue';
import {
  IconCalendar,
  IconHome,
  IconLayers,
  IconSettings,
  IconVideo,
  IconYoutube,
} from '@xsys2/components';

export default () => ({
  menuItems: {
    homepage: {
      label: 'Home page',
      iconComponent: shallowRef(IconHome),
      path: '/',
      description: null,
    },
    technicalRoom: {
      label: 'Technical Room',
      iconComponent: shallowRef(IconSettings),
      path: '/technical-room',
      description: null,
    },
    printSamples: {
      label: 'Print Samples',
      iconComponent: shallowRef(IconLayers),
      path: '/print-samples',
      description: null,
    },
    webinars: {
      label: 'Webinars',
      iconComponent: shallowRef(IconYoutube),
      path: '/webinars',
      description: null,
    },
    xsysTv: {
      label: 'XSYS TV',
      iconComponent: shallowRef(IconVideo),
      path: '/xsys-tv',
      description: null,
    },
    eventsroom: {
      label: 'Events',
      iconComponent: shallowRef(IconCalendar),
      path: '/events',
      description: null,
    },
  },
});
