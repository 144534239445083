import { mergeDeep, getElementsByIndexes } from '@xsys2/functions';
import { tutorialProps } from '@/modules/tutorial';

export default function tutorialPlayer(props, end) {
  let currentPropsIndex = -1;
  const generator = propsGenerator();

  next();
  setTimeout(next, 1000);

  function* propsGenerator() {
    while (currentPropsIndex < tutorialProps.length) {
      yield tutorialProps[++currentPropsIndex];
    }
  }
  function applyProps(newProps) {
    Object
      .keys(newProps)
      .forEach((componentName) => {
        props[componentName] = newProps[componentName];
      });
  }
  function mergeProps(currentProps, skipProps) {
    return mergeDeep(
      JSON.parse(JSON.stringify(currentProps)),
      skipProps,
    );
  }
  function next() {
    applyProps(generator.next().value);
  }
  function skip() {
    const currentProps = tutorialProps[currentPropsIndex];
    const [
      initialPositionProps,
      defaultPositionProps,
      removeStylesProps,
    ] = getElementsByIndexes(
      tutorialProps,
      tutorialProps.length - 1,
      tutorialProps.length - 2,
      tutorialProps.length - 3,
    );

    applyProps(mergeProps(currentProps, initialPositionProps));
    setTimeout(() => applyProps(mergeProps(currentProps, defaultPositionProps)), 1500);
    setTimeout(() => applyProps(mergeProps(currentProps, removeStylesProps)), 1600);
    setTimeout(end, 1380);
  }

  return { next, skip };
}
