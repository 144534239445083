<template>
  <!-- eslint-disable max-len -->
  <svg
    data-vue-component-name="LabelEvents"
    width="531"
    height="107"
    viewBox="0 0 531 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M61.1406 105H0.109375V2.20312H61.1406V24.4922H27.8828V40.6641H58.6797V62.9531H27.8828V82.3594H61.1406V105ZM136.867 2.20312H167.945L134.266 105H101.57L68.0312 2.20312H99.25L113.242 54.3047C116.148 65.8828 117.742 73.9453 118.023 78.4922C118.352 75.2109 119.008 71.1094 119.992 66.1875C120.977 61.2656 121.844 57.3984 122.594 54.5859L136.867 2.20312ZM240.156 105H179.125V2.20312H240.156V24.4922H206.898V40.6641H237.695V62.9531H206.898V82.3594H240.156V105ZM356.031 105H319.609L282.062 32.5781H281.43C282.32 43.9688 282.766 52.6641 282.766 58.6641V105H258.156V2.20312H294.438L331.844 73.6406H332.266C331.609 63.2812 331.281 54.9609 331.281 48.6797V2.20312H356.031V105ZM423.602 105H395.828V24.9141H370.727V2.20312H448.633V24.9141H423.602V105ZM530.547 73.7812C530.547 80.1562 528.93 85.8281 525.695 90.7969C522.461 95.7188 517.797 99.5625 511.703 102.328C505.609 105.047 498.461 106.406 490.258 106.406C483.414 106.406 477.672 105.938 473.031 105C468.391 104.016 463.562 102.328 458.547 99.9375V75.1875C463.844 77.9062 469.352 80.0391 475.07 81.5859C480.789 83.0859 486.039 83.8359 490.82 83.8359C494.945 83.8359 497.969 83.1328 499.891 81.7266C501.812 80.2734 502.773 78.4219 502.773 76.1719C502.773 74.7656 502.375 73.5469 501.578 72.5156C500.828 71.4375 499.586 70.3594 497.852 69.2812C496.164 68.2031 491.617 66 484.211 62.6719C477.508 59.625 472.469 56.6719 469.094 53.8125C465.766 50.9531 463.281 47.6719 461.641 43.9688C460.047 40.2656 459.25 35.8828 459.25 30.8203C459.25 21.3516 462.695 13.9687 469.586 8.67188C476.477 3.375 485.945 0.726562 497.992 0.726562C508.633 0.726562 519.484 3.1875 530.547 8.10938L522.039 29.5547C512.43 25.1484 504.133 22.9453 497.148 22.9453C493.539 22.9453 490.914 23.5781 489.273 24.8438C487.633 26.1094 486.812 27.6797 486.812 29.5547C486.812 31.5703 487.844 33.375 489.906 34.9688C492.016 36.5625 497.688 39.4688 506.922 43.6875C515.781 47.6719 521.922 51.9609 525.344 56.5547C528.812 61.1016 530.547 66.8438 530.547 73.7812Z"
      fill="white"
    />
  </svg>
</template>
