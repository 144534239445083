export default {
  getImages: state => state.printSamplesList
    .map(item => (
      {
        title: item.title,
        png: item.image.url,
        webp: item.imageWebp?.url,
      }
    )),

  getSegments: state => ['All Segments'].concat(
    Array.from(
      new Set(
        state.printSamplesList
          .map(item => item.segments)
          .flat(),
      ),
    ),
  ),
};
