function init(nodeEl, bindings) {
  if (bindings.isEnabled) {
    saveBindings.call(nodeEl, bindings);

    nodeEl.onmousemove = onMouseMove;
    nodeEl.onmouseleave = onMouseLeave;
  }
}

function onMouseMove({ offsetX, offsetY }) {
  this.cursorX = offsetX;
  this.cursorY = offsetY;

  updateStyles.call(this);
}

function onMouseLeave() {
  resetStyles.call(this);
}

function updateStyles() {
  const { xDegree, yDegree } = calcDegree.call(this);

  this.style.transition = 'all 0.08s ease-in-out';
  this.style.transform = `
    perspective(${window.innerWidth}px)
    rotateY(${xDegree}deg)
    rotateX(${yDegree}deg)
  `;
}

function resetStyles() {
  this.style.transition = 'all 0.3s ease-in-out';
  this.style.transform = `
    perspective(${window.innerWidth}px)
    rotateY(0)
    rotateX(0)
  `;
}

function calcDegree() {
  const xCenter = this.offsetWidth / 2;
  const yCenter = this.offsetHeight / 2;

  const xPercent = ((100 * this.cursorX) / xCenter) - 100;
  const yPercent = -(((100 * this.cursorY) / yCenter) - 100);

  const xDegree = (this.maxDegree * xPercent) / 100;
  const yDegree = (this.maxDegree * yPercent) / 100;

  return { xDegree, yDegree };
}

function saveBindings(value) {
  this.isEnabled = value.isEnabled;
  this.maxDegree = value.maxDegree;
}

export default {
  mounted(
    nodeEl,
    bindings = { value: { isEnabled: true, maxDegree: 15 } },
  ) {
    init(nodeEl, bindings.value);
  },
  beforeUpdate(
    nodeEl,
    bindings = { value: { isEnabled: true, maxDegree: 15 } },
  ) {
    init(nodeEl, bindings.value);
  },
  beforeUnmount(
    nodeEl,
    bindings = { value: { isEnabled: true, maxDegree: 15 } },
  ) {
    if (bindings.value.isEnabled) {
      resetStyles.call(nodeEl);
    }
  },
};
