<template>
  <div
    data-vue-component-name="PreviewPrintSamples"
    :class="{ 'hovered': !infoMode && isHovered }"
    :style="{ 'cursor': infoMode && 'default' }"
    ref="preview"
  >
    <section>
      <!-- slogan -->
      <span :class="{ 'info-mode': infoMode }">
        Print solid. Stay flexible.
      </span>

      <!-- cards -->
      <div
        :class="[
          'cards',
          { 'info-mode': infoMode }
        ]"
      >
        <AppImage
          v-for="(item, index) in printSamplesList"
          :key="index"
          :webp="item.webp"
          :png="item.png"
          class="card"
        />
      </div>

      <AppTooltip
        :additional-condition="infoMode"
        :message="message"
        :style="{ cursor: infoMode && 'pointer' }"
      >
        <h2>Print samples</h2>

        <transition name="fade">
          <IconInfo v-if="infoMode" />
        </transition>
      </AppTooltip>
    </section>

    <img
      src="@/assets/images/previews/print-samples.png"
      alt="print samples background"
      :class="{ 'info-mode': infoMode }"
    >
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IconInfo, AppImage } from '@xsys2/components';
import AppTooltip from '@/components/AppTooltip.vue';
import { listenHover } from '@/composables';

defineProps({
  infoMode: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const printSamplesList = computed(
  () => store.getters['printSamples/getImages'],
);

if (!printSamplesList.value.length) {
  store.dispatch('printSamples/getData');
}

const preview = ref(null);
const { isHovered } = listenHover(preview);

const message = 'Hac tortor, sit eget diam pretium. In elit enim, malesuada'
  + 'lectus faucibus a nam. Volutpat ultrices tristique tortor'
  + 'morbi. Et pulvinar quis lorem ac etiam nunc.';
</script>

<style scoped lang="scss">
@import "src/assets/styles/pages/home/preview.scss";

[data-vue-component-name="PreviewPrintSamples"] {
  transform-origin: bottom left;
  width: 45.9%;
  height: 34%;
  margin: 0 0 0 -8.9%;
  @include wrapperStyles;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 53.5%;
    height: 75%;
    margin-left: 19.5%;
    padding: 3.5% 6% 1% 5%;

    span {
      color: #fff;
      font-weight: 300;
      margin-bottom: 6%;
      font-size: 0.93vw;
      transition: all .25s ease-in-out 0s;
    }

    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15% 5%;
      transition: all .25s ease-in-out 0s;

      [data-vue-component-name="AppImage"] {
        object-fit: cover;
        width: 2.1vw;
        height: 2.6vw;
        box-shadow:
          inset 0 0 0 0.15vw rgba(255, 255, 255, 0.2),
          0 2px 6px rgba(0, 0, 0, 0.15);
      }
    }

    [data-vue-component-name="AppTooltip"] {
      position: relative;
      margin-top: 13%;

      h2 {
        @include labelStyles;
      }

      svg {
        position: absolute;
        top: -0.2vw;
        left: 100%;
        width: 1.9vw;
        height: 1.9vw;
        padding: 0.4vw;
      }
    }
  }

  img {
    @include absolute-full;
    width: 100%;
    height: 128%;
    transition: all .25s ease-in-out 0s;
  }
}
</style>
