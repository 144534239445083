<template>
  <div
    data-vue-component-name="PrintSamplesCard"
    :class="activeMode"
    :style="getCardStyles"
  >
    <AppImage
      v-hover-3d="{
        isEnabled: isActiveHover,
        maxDegree: 20,
      }"
      :webp="card.imageWebp?.url"
      :png="card.image?.url"
      :alt="alt"
      @mouseenter="onMouseEnter"
      @mouseleave="$emit('updateHoverStatus', false)"
    />

    <PrintSamplesCardHint
      :flip="hintAlign === 'left'"
      :class="[
        hintAlign,
        { hovered: isActiveHover },
      ]"
      :text="card.title"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { AppImage } from '@xsys2/components';
import { isAllKeysExist } from '@xsys2/functions';
import { hintAlignmentController } from '@/modules/printSamples';

const props = defineProps({
  activeMode: {
    type: String,
    default: null,
    validator: value => ['scale', 'squeeze', 'expand']
      .includes(value),
  },
  card: {
    type: Object,
    default: null,
    validator: value => isAllKeysExist(value, ['image', 'imageWebp', 'title']),
  },
  alt: {
    type: String,
    default: null,
  },
  isActiveHover: {
    type: Boolean,
    default: false,
  },
  isPassiveHover: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits({
  updateHoverStatus: value => typeof value === 'boolean',
});

const squeezeTranslates = [
  getRandomOffset(1.3),
  getRandomOffset(1.3),
];
const expandTranslates = [
  getRandomOffset(3.5),
  getRandomOffset(3.5),
];
function getRandomOffset(maxOffset) {
  let randomValue = Math.random();
  randomValue = randomValue >= 0.5
    ? randomValue
    : -randomValue;

  return (maxOffset * randomValue)
    .toFixed(1)
    .concat('vw');
}

const getCardStyles = computed(() => {
  const styles = {};

  if (props.activeMode === 'scale') {
    styles.transition = 'all 2s ease-in-out';
  } else
  if (props.activeMode === 'squeeze') {
    styles.transition = 'all 0.5s ease-in-out';
    styles.transform = convertToCssTransform(squeezeTranslates);
    styles.transformOrigin = fixOrigin(squeezeTranslates);
  } else
  if (props.activeMode === 'expand') {
    styles.transition = 'all 2s ease-in-out';
    styles.transform = convertToCssTransform(expandTranslates);
    styles.transformOrigin = fixOrigin(expandTranslates);

    if (props.isActiveHover) {
      styles.transition = 'all 0.5s ease-in-out 0.1s';
      styles.transform += 'scale(1.1)';
      styles.zIndex = 1;
      styles.cursor = 'pointer';
    } else
    if (props.isPassiveHover) {
      styles.transition = 'all 0.5s ease-in-out 0.1s';
      styles.filter = 'brightness(0.5)';
    }
  }

  return styles;
});
const fixOrigin = (values = []) => values
  .map(value => `calc(${value} + 50%)`)
  .join(' ');
const convertToCssTransform = (values = []) => `translate(${values.join(',')})`;

const hintAlign = ref(null);

// FIXME
const onMouseEnter = ({ target }) => {
  emits('updateHoverStatus', true);

  if (props.activeMode === 'expand' && !hintAlign.value) {
    hintAlign.value = hintAlignmentController(target.nextSibling);
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamplesCard"] {
  position: relative;

  [data-vue-component-name="AppImage"] {
    width: 4.948vw;
    height: 5.781vw;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
    transition: all 2s ease-in-out;
  }

  &.scale {
    [data-vue-component-name="AppImage"] {
      width: 7.4vw;
      height: 16vh;
    }
  }

  &.squeeze {
    [data-vue-component-name="AppImage"] {
      width: 7.4vw;
      height: 16vh;
    }
  }

  &.expand {
    [data-vue-component-name="AppImage"] {
      width: 7.4vw;
      height: 14vh;
    }
  }

  [data-vue-component-name="PrintSamplesCardHint"] {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition:
      transform 0.75s ease-in-out,
      opacity 0.75s ease-in-out;

    &.left {
      left: 0;
      transform: translate(-47.5%, 0);
    }

    &.right {
      left: 100%;
      transform: translate(-52.5%, 0);
    }

    &.hovered {
      opacity: 1;

      &.left {
        transform: translate(-47.5%, -50%);
      }

      &.right {
        transform: translate(-52.5%, -50%);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  [data-vue-component-name="PrintSamplesCard"] {
    [data-vue-component-name="AppImage"] {
      width: 12vw;
    }
  }
}
</style>
