<template>
  <div data-vue-component-name="XsysTvSlider">
    <VueperSlides
      v-if="transformedLinks.length"
      :gap="5"
      :visible-slides="3"
      :dragging-distance="5"
      fixed-height="20.833vw"
      :arrows="false"
      :bullets="false"
      infinite
      prevent-y-scroll
      arrowsOutside
      touchable
      class="no-shadow"
      :class="{
        'offset-slide--first': firstSlide,
        'offset-slide--last': lastSlide,
      }"
      ref="slider"
      @before-slide="onDragStart"
      @slide="({ currentSlide: { index } }) => onDragEnd(index)"
    >
      <VueperSlide
        v-for="(link, index) in transformedLinks"
        :key="`${index}-${index}`"
        :class="{
          'vueperslide--active': index === modelValue,
          'single-view': isSingleView,
        }"
        @click="onSlideClick(index)"
      >
        <template #content>
          <YoutubePreview
            :src="link.imagePath"
            @click="playVideo"
          />

          <YoutubePlayer
            v-if="(index === modelValue) && isVideoPlaying"
            autoplay
            :video-id="link.videoId"
          />
        </template>
      </VueperSlide>
    </VueperSlides>

    <!-- close single view mode -->
    <transition name="fade">
      <ButtonClose
        v-if="isSingleView"
        @click="toggleSingleView(false)"
      />
    </transition>

    <!-- custom control buttons -->
    <transition name="fade">
      <div
        v-if="!isSingleView"
        class="control-buttons"
      >
        <button @click="changeActiveSlide(modelValue - 1)">
          <IconArrowLarge />
        </button>

        <button @click="changeActiveSlide(modelValue + 1)">
          <IconArrowLarge />
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  toRef,
  computed,
} from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import {
  IconArrowLarge,
  YoutubePlayer,
  YoutubePreview,
  ButtonClose,
} from '@xsys2/components';
import {
  findYoutubeVideoId,
  getYoutubeVideoThumbnailUrl,
  getValidRange,
} from '@xsys2/functions';

const props = defineProps({
  links: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Number,
    default: null,
  },
});

const slider = ref(null);
const firstSlide = ref(true);
const lastSlide = ref(false);
const isMoved = ref(false);
let timeout;

// refresh the active slide when the modelValue index changes
watch(toRef(props, 'modelValue'), index => changeActiveSlide(index));

const emits = defineEmits({
  'update:modelValue': value => typeof value === 'number',
});

const isVideoPlaying = ref(false);
const toggleVideoPlaying = value => (isVideoPlaying.value = value);

const isSingleView = ref(false);
const toggleSingleView = (value) => {
  // prohibit switching to single view during dragging
  if (!isMoved.value) {
    isSingleView.value = value;
  }

  // stops the video when you exit single view
  if (!value) {
    toggleVideoPlaying(false);
  }
};

const onDragStart = ({ nextSlide }) => {
  isMoved.value = true;

  // Makes boundary slides in the center
  firstSlide.value = !nextSlide.index;
  lastSlide.value = nextSlide.index === props.links.length - 1;
};
const onDragEnd = (index) => {
  // prevent clicking after dragging
  clearTimeout(timeout);
  timeout = setTimeout(() => (isMoved.value = false), 200);

  emits('update:modelValue', index);
};
const onSlideClick = (index) => {
  if (props.modelValue === index) { // if clicked on active slide
    toggleSingleView(true);
  } else {
    emits('update:modelValue', index);
  }
};

const changeActiveSlide = (index) => {
  toggleVideoPlaying(false);

  // does not allow to go beyond the navigation
  slider.value.goToSlide(
    getValidRange(index, props.links.length - 1, 0),
  );
};

const playVideo = () => {
  if (isSingleView.value) {
    toggleVideoPlaying(true);
  }
};

const transformedLinks = computed(
  () => props.links.map((link) => {
    const videoId = findYoutubeVideoId(link);
    const imagePath = getYoutubeVideoThumbnailUrl(videoId, 'maximum');

    return {
      videoId,
      imagePath,
    };
  }),
);
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTvSlider"] {
  @include flex-center;
  flex-direction: column;

  &::v-deep(.vueperslides) {
    width: 100vw;
    height: 20.833vw;
    margin-bottom: 0;
    margin-top: 5vw;

    &.offset-slide--first .vueperslides__track-inner {
      transform: translate3d(33.333%, 0, 0) !important;
    }

    &.offset-slide--last .vueperslides__track-inner {
      transform: translate3d(-33.3%, 0, 0) !important;
    }

    .vueperslides__parallax-wrapper {
      overflow: visible;

      .vueperslides__track {
        cursor: default;
        overflow: visible;

        .vueperslides__track-inner {
          align-items: center;

          .vueperslide {
            cursor: pointer;
            height: 16.667vw;
            transform: translateY(0) scale(1);
            transition: all 1s ease-in-out;

            &--active {
              transform: scale(1.2);
            }
          }

          .single-view {
            opacity: 0;
            transform: translateY(-50vw);

            &.vueperslide--active {
              position: relative;
              margin-left: 8vw;
              opacity: 1;
              transform: translateY(0) scale(2);
              transition: all 1s ease-in-out;

              [data-vue-component-name="YoutubePlayer"] {
                @include absolute-full(important);
                z-index: 10;
              }
            }
          }
        }
      }
    }
  }

  [data-vue-component-name="ButtonClose"] {
    position: absolute;
    top: 0;
    left: 56.5vw;
    transform: translateX(-50%);
    margin-top: 2.500vw;
  }

  .control-buttons {
    margin-right: 28vw;
    margin-top: 2vw;

    button {

      &:first-child svg {
        transform: rotate(180deg);
      }

      svg {
        width: 6vw;
      }
    }
  }
}
</style>
