import { ref, watch, onBeforeUnmount } from 'vue';
import { toggleServiceWrapper } from '@/modules';

export default function infoController(props) {
  const previews = [
    'PreviewPrintSamples',
    'PreviewEvents',
    'PreviewXsysTv',
    'PreviewWebinars',
    'PreviewTechnicalRoom',
  ];
  const isInfoVisible = ref(false);
  const serviceWrapperProps = {
    bgColor: 'rgba(0, 0, 0, 0.5)',
    blur: '8',
    style: {
      zIndex: 2,
    },
  };

  watch(isInfoVisible, toggleInfoMode);

  function toggleInfoMode(isInfoMode) {
    toggleServiceWrapper(isInfoMode ? serviceWrapperProps : null);
    setPreviewsProps({ infoMode: isInfoMode });
  }

  function setPreviewsProps(newProps) {
    previews.forEach((previewName) => {
      props[previewName] = newProps;
    });
  }

  onBeforeUnmount(() => toggleInfoMode(false));

  return {
    isInfoVisible,
  };
}
