import { forEach, recursiveCamelCase, has } from '@xsys2/functions';

export default {
  SET_HOME_DATA(state, payload) {
    forEach(recursiveCamelCase(payload), (key, value) => {
      if (has(state, key)) {
        state[key] = value;
      }
    });
  },
  SET_HOME_PAGE_SIZE(state, size) {
    state.homePageSize = size;
  },
  SET_TUTORIAL_DATA(state, { steps }) {
    state.tutorial = steps;
  },
};
