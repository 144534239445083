<template>
  <AppWrapper
    data-vue-component-name="TheToolsCard"
    bg-color="rgba(255, 255, 255, 0.2)"
  >
    <div class="head">
      <img
        v-if="data.icon"
        :src="data.icon.url"
        :alt="data.title"
        @error="selfDestroy"
      >
      <h3>{{ data.title }}</h3>
    </div>

    <div
      class="content"
      v-html="data.content"
    />

    <a
      :href="data.url"
      rel="noopener"
      target="__blank"
    >
      <span>Button</span>
      <IconArrow />
    </a>
  </AppWrapper>
</template>

<script setup>
import { isAllKeysExist } from '@xsys2/functions';
import { AppWrapper, IconArrow } from '@xsys2/components';

defineProps({
  data: {
    type: Object,
    default: null,
    validator: value => isAllKeysExist(
      value,
      [
        'icon',
        'iconWebp',
        'title',
        'content',
        'url',
      ],
    ),
  },
});

const selfDestroy = ({ target }) => target.remove();
</script>

<style scoped lang="scss">
$primary: map-get($theme-colors, 'primary');

[data-vue-component-name="TheToolsCard"] {
  padding: 2.52vw 1.5vw;
  border-radius: 0.417vw;

  @include firefox {
    background: rgba(255, 255, 255, 0.3) !important;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1vw;

    img {
      width: 1.250vw;
      height: 1.250vw;
      overflow: hidden;
      margin-right: 0.8vw;
    }

    h3 {
      font-weight: 800;
      font-size: 1.25vw;
    }
  }

  .content::v-deep(p) {
    margin-bottom: 1.5vw;
    opacity: 0.75;
    line-height: 150%;
    font-size: 0.85vw;

    @include firefox {
      opacity: 1;
    }
  }

  a {
    @include flex-center;
    width: max-content;
    padding: 0.6vw 2.65vw;
    background-color: $primary;
    border-radius: 3.333vw;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 0.833vw;
      line-height: 1.146vw;
      color: #FFFFFF;
      margin-right: 1vw;
    }

    [data-vue-component-name="IconArrow"] {
      width: 0.833vw;
      height: 0.833vw;
    }
  }
}
</style>
