<template>
  <div data-vue-component-name="YoutubePlayerWithForm">
    <YoutubePreview
      :src="thumbnail"
      @click="onPreviewClick"
    />

    <transition name="fade">
      <YoutubePlayer
        v-if="videoId && isVideoPlaying"
        :video-id="videoId"
        autoplay
      />
    </transition>

    <transition name="fade">
      <AppWrapper
        v-if="isWrapperVisible && !isVideoPlaying"
        bg-color="rgba(0, 0, 0, 0.8)"
        blur="24"
      >
        <div class="left">
          <div class="container">
            <div class="title">{{ title }}</div>
            <div class="description" v-html="description" />
          </div>
        </div>

        <AppWrapper
          bg-color="rgba(255, 255, 255, 0.08)"
          class="right"
        >
          <AppForm
            :title="form.formTitle"
            :description="form.formText"
            :inputs="form.fields"
            @on-submit="onFormSubmit"
          />
        </AppWrapper>
      </AppWrapper>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, toRef } from 'vue';
import {
  YoutubePreview,
  YoutubePlayer,
  AppWrapper,
  AppForm,
  } from '@xsys2/components';

import {
  findYoutubeVideoId,
  getYoutubeVideoThumbnailUrl,
  isAllKeysExist,
} from '@xsys2/functions';

const props = defineProps({
  videoLink: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  form: {
    type: Object,
    default: null,
    validator: value => isAllKeysExist(value, [
      'formTitle',
      'formText',
      'fields',
    ]),
  },
});

const emits = defineEmits({
  onFormSubmit: value => isAllKeysExist(value, [
    'resetForm',
    'values',
  ]),
});

const videoId = ref(null);
const thumbnail = ref(null);
const isWrapperVisible = ref(false);
const isVideoPlaying = ref(false);

watch(
  toRef(props, 'videoLink'),
  (link) => {
    videoId.value = findYoutubeVideoId(link);
    thumbnail.value = getYoutubeVideoThumbnailUrl(videoId.value, 'maximum');
  },
  {
    immediate: true,
  },
);

const onPreviewClick = () => {
  isWrapperVisible.value = true;
};

const onFormSubmit = ({ values, resetForm }) => emits(
  'onFormSubmit',
  {
    values,
    callback() {
      resetForm();
      isWrapperVisible.value = false;
      isVideoPlaying.value = true;
    },
  },
);
</script>

<style scoped lang="scss">
[data-vue-component-name="YoutubePlayerWithForm"] {
  position: relative;
  aspect-ratio: 16 / 9;

  & > {
    [data-vue-component-name=YoutubePreview] {
      @include absolute-full;
      z-index: 0;
      cursor: pointer;
    }

    [data-vue-component-name=YoutubePlayer] {}

    [data-vue-component-name=AppWrapper] {
      @include absolute-full;
      @include flex-center;
      z-index: 1;

      @include firefox {
        background-color: rgba(0, 0, 0, 0.95) !important;
      }

      .left {
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        .container {
          margin-left: 6.250vw;
          color: #FFFFFF;
          font-style: normal;
          max-width: 14.844vw;
          width: 100%;

          .title {
            font-weight: 800;
            font-size: 2.083vw;
            line-height: 2.813vw;
            margin-bottom: 1.250vw;
          }

          .description {
            font-size: 1.250vw;
            line-height: 150%;
            opacity: 0.9;
          }
        }
      }

      .right {
        height: 100%;
        max-width: 20.833vw;
        width: 100%;
        padding: 2.500vw;
        @include flex-center;

        @include firefox {
          background-color: rgba(255, 255, 255, 0.35) !important;
        }

        [data-vue-component-name=AppForm] {}
      }
    }
  }
}
</style>
