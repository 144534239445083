import { computed } from 'vue';
import store from '@/store';

import WomanImageWebp from '@/assets/images/persons/woman.webp';
import WomanImagePng from '@/assets/images/persons/woman.png';

import Woman2ImageWebp from '@/assets/images/persons/woman-2.webp';
import Woman2ImagePng from '@/assets/images/persons/woman-2.png';

const tutorial = computed(() => store.state.home.tutorial);

if (!tutorial.value.length) {
  store.dispatch('home/getTutorialData');
}

/* COMPONENTS ORDER
 Welcome
 Triangle
 Person1
 Person2
 AppLogo
 ButtonsContainer1
 ButtonsContainer2
 PreviewPointer1
 PreviewPointer2
 PreviewDescription1
 PreviewDescription2
 PreviewPrintSamples
 PreviewEvents
 PreviewXsysTv
 PreviewWebinars
 PreviewTechnicalRoom
 ButtonStart
*/

export default [
  // initial
  {
    Welcome: {
      style: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        visibility: 'hidden',
        opacity: 0,
      },
    }, // positioning
    Triangle: {
      style: {
        position: 'absolute',
        top: '100%',
        right: 0,
        width: '18.86%',
        height: '21.3%',
      },
    }, // positioning
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        right: '3%',
        opacity: 0,
        visibility: 'hidden',
        width: '10.97%',
      },
    }, // positioning
    Person2: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '35.55%',
        opacity: 0,
        visibility: 'hidden',
        width: '10.97%',
      },
    }, // positioning
    AppLogo: {
      style: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '22.5%',
        transform: 'translate(-50%, -50%)',
      },
    }, // active
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '62%',
        left: '50%',
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateX(-50%)',
      },
    }, // positioning
    ButtonsContainer2: {
      style: {
        position: 'absolute',
        top: '54%',
        left: '62.5%',
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateX(-50%)',
      },
    }, // positioning
    PreviewPointer1: {
      style: {
        position: 'absolute',
        top: '37.3%',
        left: '35.1%',
        zIndex: 40,
        opacity: 0,
        visibility: 'hidden',
      },
    }, // positioning
    PreviewPointer2: {
      style: {
        position: 'absolute',
        top: '35%',
        right: '29%',
        zIndex: 40,
        opacity: 0,
        visibility: 'hidden',
      },
    }, // positioning
    PreviewDescription1: {
      style: {
        position: 'absolute',
        top: '35.1%',
        left: '50%',
        zIndex: 40,
        opacity: 0,
        visibility: 'hidden',
      },
    }, // positioning
    PreviewDescription2: {
      style: {
        position: 'absolute',
        top: '31%',
        left: '20%',
        zIndex: 40,
      },
    }, // positioning
    ButtonStart: {
      style: {
        position: 'absolute',
        top: '42.5%',
        right: '38%',
        opacity: 0,
        transition: 'none',
        visibility: 'hidden',
      },
    }, // positioning
  },
  // slide up logo
  // fade-in: Welcome, ButtonsContainer1, Person1, Triangle
  {
    Welcome: {
      style: {
        position: 'absolute',
        top: '36%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
        textAlign: 'center',
        visibility: 'visible',
        opacity: 1,
      },
    }, // active
    Triangle: {
      style: {
        position: 'absolute',
        top: '78.6%',
        right: 0,
        width: '18.86%',
        height: '21.3%',
        transition: 'top 1.5s ease-in-out',
      },
    }, // active
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        right: '3%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
        opacity: 1,
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // active
    AppLogo: {
      style: {
        position: 'absolute',
        top: '12%',
        left: '50%',
        width: '12%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
      },
    }, // active
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '51%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
        visibility: 'visible',
        opacity: 1,
      },
    }, // active
    PreviewPrintSamples: {
      style: {
        zIndex: 60,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transition: 'none',
      },
    }, // positioning
    PreviewEvents: {
      style: {
        zIndex: 60,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transition: 'none',
      },
    }, // positioning
    PreviewXsysTv: {
      style: {
        zIndex: 40,
        filter: 'brightness(0.15) blur(8px)',
        transition: 'none',
        pointerEvents: 'none',
      },
    }, // positioning
    PreviewWebinars: {
      style: {
        zIndex: 40,
        filter: 'brightness(0.15) blur(8px)',
        transition: 'none',
        pointerEvents: 'none',
      },
    }, // positioning
    PreviewTechnicalRoom: {
      style: {
        zIndex: 40,
        filter: 'brightness(0.15) blur(8px)',
        transition: 'none',
        pointerEvents: 'none',
      },
    }, // positioning
  },
  // Print Samples
  {
    Welcome: {
      style: {
        position: 'absolute',
        top: '36%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
        textAlign: 'center',
        visibility: 'hidden',
        opacity: 0,
      },
    }, // hiding
    Triangle: {
      style: {
        position: 'absolute',
        top: '100%',
        right: 0,
        width: '18.86%',
        height: '21.3%',
        transition: 'top 1.5s ease-in-out',
      },
    }, // hiding
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        right: '3%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
        opacity: 0,
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // hiding
    Person2: {
      style: {
        position: 'absolute',
        top: '62.5%',
        left: '36%',
        opacity: 1,
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
      },
      webp: Woman2ImageWebp,
      png: Woman2ImagePng,
    }, // active
    AppLogo: {
      style: {
        position: 'absolute',
        top: '12%',
        left: '50%',
        width: '12%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
        opacity: 0,
        visibility: 'hidden',
      },
    }, // hiding
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '53.5%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out',
        visibility: 'hidden',
        opacity: 0,
      },
    }, // hiding
    ButtonsContainer2: {
      style: {
        position: 'absolute',
        top: '52%',
        left: '62.5%',
        transform: 'translateX(-50%)',
        transition: 'all 1.5s ease-in-out 3s',
      },
    }, // active
    PreviewPointer1: {
      animate: true,
      style: {
        position: 'absolute',
        top: '37.3%',
        left: '35.15%',
        zIndex: 40,
      },
    }, // active
    PreviewDescription1: {
      animate: true,
      style: {
        position: 'absolute',
        top: '35.1%',
        left: '50%',
        zIndex: 40,
      },
      title: computed(() => tutorial.value[0]?.title),
      description: computed(() => tutorial.value[0]?.text),
    }, // active
    PreviewPrintSamples: {
      style: {
        zIndex: 60,
        pointerEvents: 'none',
        filter: 'brightness(1) blur(0)',
        transform: 'scale(1.3)',
        transition: 'all 1.5s ease-in-out',
      },
    }, // active
  },
  // Events
  {
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '50%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out 1.5s',
        opacity: 1,
        transform: 'rotateY(180deg) translateX(50%)',
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // active
    Person2: {
      style: {
        position: 'absolute',
        top: '62.5%',
        left: '36%',
        opacity: 0,
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
      },
      webp: Woman2ImageWebp,
      png: Woman2ImagePng,
    }, // hiding
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '49%',
        left: '32.4%',
        transform: 'translateX(-50%) translateY(-25%)',
        opacity: 1,
        transition:
          'opacity 1.5s ease-in-out 4.5s,'
          + 'transform 1.5s ease-in-out 4.5s',
      },
    }, // active
    ButtonsContainer2: {
      style: {
        position: 'absolute',
        top: '52%',
        left: '62.5%',
        transform: 'translate(-50%, 25%)',
        visibility: 'hidden',
        opacity: 0,
        transition:
          'opacity 1.5s ease-in-out 0s,'
          + 'visibility 1.5s ease-in-out 1.5s,'
          + 'transform 1.5s ease-in-out',
      },
    }, // hiding
    PreviewPointer1: {
      style: {
        position: 'absolute',
        top: '37.3%',
        left: '35.15%',
        zIndex: 40,
      },
    }, // hiding
    PreviewPointer2: {
      animate: true,
      delay: 3,
      flip: true,
      style: {
        position: 'absolute',
        top: '35%',
        right: '29%',
        zIndex: 40,
      },
    }, // active
    PreviewDescription1: {
      style: {
        position: 'absolute',
        top: '35.1%',
        left: '50%',
        zIndex: 40,
        visibility: 'hidden',
        transition: 'visibility 0s 3s',
      },
      title: computed(() => tutorial.value[0]?.title),
      description: computed(() => tutorial.value[0]?.text),
    }, // hiding
    PreviewDescription2: {
      animate: true,
      delay: 3.5,
      style: {
        position: 'absolute',
        top: '31%',
        left: '20%',
        zIndex: 40,
      },
      title: computed(() => tutorial.value[1]?.title),
      description: computed(() => tutorial.value[1]?.text),
    }, // active
    PreviewEvents: {
      style: {
        zIndex: 60,
        pointerEvents: 'none',
        filter: 'brightness(1) blur(0)',
        transform: 'scale(1.5)',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // active
    PreviewPrintSamples: {
      style: {
        zIndex: 40,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // hiding
  },
  // Xsys tv
  {
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '50%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
        opacity: 0,
        transform: 'rotateY(180deg) translateX(50%)',
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // hiding
    Person2: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '10%',
        width: '10.97%',
        opacity: 1,
        transform: 'rotateY(180deg)',
        transition: 'opacity 1.5s ease-in-out 1.5s',
      },
      webp: Woman2ImageWebp,
      png: Woman2ImagePng,
    }, // active
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '49%',
        left: '32.4%',
        transform: 'translateX(-50%)',
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 1.5s ease-in-out',
      },
    }, // hiding
    ButtonsContainer2: {
      style: {
        position: 'absolute',
        top: '48%',
        left: '58.4%',
        transform: 'translateX(-50%) translateY(-25%)',
        opacity: 1,
        transition:
          'opacity 1.5s ease-in-out 4.5s,'
          + 'transform 1.5s ease-in-out 4.5s',
      },
    }, // active
    PreviewPointer1: {
      animate: true,
      delay: 3,
      style: {
        position: 'absolute',
        top: '32%',
        left: '30.5%',
        zIndex: 40,
      },
    }, // active
    PreviewPointer2: {
      flip: true,
      style: {
        position: 'absolute',
        top: '35%',
        right: '29%',
        zIndex: 40,
      },
    }, // hiding
    PreviewDescription1: {
      animate: true,
      delay: 3.5,
      style: {
        position: 'absolute',
        top: '30%',
        left: '46%',
        zIndex: 40,
      },
      title: computed(() => tutorial.value[2]?.title),
      description: computed(() => tutorial.value[2]?.text),
    }, // active
    PreviewDescription2: {
      style: {
        position: 'absolute',
        top: '31%',
        left: '20%',
        zIndex: 40,
        visibility: 'hidden',
        transition: 'visibility 0s 3s',
      },
      title: computed(() => tutorial.value[1]?.title),
      description: computed(() => tutorial.value[1]?.text),
    }, // hiding
    PreviewEvents: {
      style: {
        zIndex: 40,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // hiding
    PreviewXsysTv: {
      style: {
        zIndex: 40,
        pointerEvents: 'none',
        filter: 'brightness(1) blur(0)',
        transform: 'scale(1.5)',
        transformOrigin: 'center',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // active
  },
  // Webinars
  {
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        right: '10%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out 1.5s',
        opacity: 1,
        transform: 'translateX(50%)',
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // active
    Person2: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '10%',
        width: '10.97%',
        opacity: 0,
        transform: 'rotateY(180deg)',
        transition: 'opacity 1.5s ease-in-out',
      },
      webp: Woman2ImageWebp,
      png: Woman2ImagePng,
    }, // hiding
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '57%',
        left: '37.6%',
        transform: 'translateX(-50%) translateY(-25%)',
        opacity: 1,
        transition:
          'opacity 1.5s ease-in-out 4.5s,'
          + 'transform 1.5s ease-in-out 4.5s',
      },
    }, // active
    ButtonsContainer2: {
      style: {
        position: 'absolute',
        top: '48%',
        left: '58.4%',
        transform: 'translateX(-50%)',
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 1.5s ease-in-out',
      },
    }, // hiding
    PreviewPointer1: {
      style: {
        position: 'absolute',
        top: '32%',
        left: '30.5%',
        zIndex: 40,
      },
    }, // hiding
    PreviewPointer2: {
      animate: true,
      delay: 3,
      flip: true,
      style: {
        position: 'absolute',
        top: '43%',
        right: '23%',
        zIndex: 40,
      },
    }, // active
    PreviewDescription1: {
      style: {
        position: 'absolute',
        top: '30%',
        left: '46%',
        zIndex: 40,
        visibility: 'hidden',
        transition: 'visibility 0s 3s',
      },
      title: computed(() => tutorial.value[2]?.title),
      description: computed(() => tutorial.value[2]?.text),
    }, // hiding
    PreviewDescription2: {
      animate: true,
      delay: 3.5,
      style: {
        position: 'absolute',
        top: '39%',
        left: '25%',
        zIndex: 40,
      },
      title: computed(() => tutorial.value[3]?.title),
      description: computed(() => tutorial.value[3]?.text),
    }, // active
    PreviewXsysTv: {
      style: {
        zIndex: 40,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // hiding
    PreviewWebinars: {
      style: {
        zIndex: 50,
        pointerEvents: 'none',
        filter: 'brightness(1) blur(0)',
        transform: 'scale(1.5)',
        transformOrigin: 'center',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // active
  },
  // Technical Room
  {
    Person1: {
      style: {
        position: 'absolute',
        top: '65%',
        right: '10%',
        width: '10.97%',
        transition: 'opacity 1.5s ease-in-out',
        opacity: 0,
        transform: 'translateX(50%)',
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // hiding
    Person2: {
      style: {
        position: 'absolute',
        top: '65%',
        left: '10%',
        width: '10.97%',
        transform: 'rotateY(180deg)',
        transition: 'opacity 1.5s ease-in-out 1.5s',
      },
      webp: WomanImageWebp,
      png: WomanImagePng,
    }, // active
    ButtonsContainer1: {
      style: {
        position: 'absolute',
        top: '57%',
        left: '37.6%',
        transform: 'translateX(-50%)',
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 1.5s ease-in-out',
      },
    }, // hiding
    PreviewPointer1: {
      animate: true,
      delay: 3,
      style: {
        position: 'absolute',
        top: '28%',
        left: '34%',
        zIndex: 40,
      },
    }, // active
    PreviewPointer2: {
      flip: true,
      style: {
        position: 'absolute',
        top: '43%',
        right: '23%',
        zIndex: 40,
      },
    }, // hiding
    PreviewDescription1: {
      animate: true,
      delay: 3.5,
      style: {
        position: 'absolute',
        top: '26%',
        left: '50%',
        zIndex: 40,
      },
      title: computed(() => tutorial.value[4]?.title),
      description: computed(() => tutorial.value[4]?.text),
    }, // active
    PreviewDescription2: {
      style: {
        position: 'absolute',
        top: '39%',
        left: '25%',
        zIndex: 40,
        visibility: 'hidden',
        transition: 'visibility 0s 3s',
      },
      title: computed(() => tutorial.value[3]?.title),
      description: computed(() => tutorial.value[3]?.text),
    }, // hiding
    PreviewWebinars: {
      style: {
        zIndex: 40,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transformOrigin: 'center',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // hiding
    PreviewTechnicalRoom: {
      style: {
        zIndex: 60,
        pointerEvents: 'none',
        filter: 'brightness(1) blur(0)',
        transform: 'scale(1.5)',
        transformOrigin: 'center',
        transition: 'all 1.5s ease-in-out 1.5s',
      },
    }, // active
    ButtonStart: {
      style: {
        position: 'absolute',
        top: '44%',
        right: '38%',
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(-25%)',
        transition:
          'opacity 1.5s ease-in-out 4.5s,'
          + 'transform 1.5s ease-in-out 4.5s',
      },
    }, // active
  },
  // Skip: initial position
  {
    Welcome: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 1s ease-in-out',
      },
    },
    Triangle: {
      style: {
        position: 'absolute',
        top: '100%',
        right: 0,
        width: '18.86%',
        height: '21.3%',
        transition: 'top 1.5s ease-in-out',
      },
    },
    Person1: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1.5s ease-out',
      },
    },
    Person2: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1.5s ease-in-out',
      },
    },
    AppLogo: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 1.5s ease-int-out',
      },
    },
    ButtonsContainer1: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1s ease-in-out 0s',
      },
    },
    ButtonsContainer2: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1s ease-in-out 0s',
      },
    },
    PreviewPointer1: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },
    },
    PreviewPointer2: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },
    },
    PreviewDescription1: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },
    },
    PreviewDescription2: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
      },
    },
    PreviewPrintSamples: {
      style: {
        zIndex: 50,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out',
      },
    },
    PreviewEvents: {
      style: {
        zIndex: 50,
        pointerEvents: 'none',
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out',
      },
    },
    PreviewXsysTv: {
      style: {
        zIndex: 50,
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out',
      },
    },
    PreviewWebinars: {
      style: {
        zIndex: 50,
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out',
      },
    },
    PreviewTechnicalRoom: {
      style: {
        zIndex: 50,
        filter: 'brightness(0.15) blur(8px)',
        transform: 'scale(1)',
        transition: 'all 1.5s ease-in-out',
      },
    },
    ButtonStart: {
      style: {
        position: 'absolute',
        top: '44%',
        right: '38%',
        opacity: 0,
        transform: 'translateY(0)',
        pointerEvents: 'none',
        transition:
          'transform 1.5s ease-in-out,'
          + 'opacity 1s ease-in-out',
      },
    },
  },
  // Skip: default position
  {
    Welcome: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 1s ease-in-out',
      },
    },
    Triangle: {
      style: {
        position: 'absolute',
        top: '100%',
        right: 0,
        width: '18.86%',
        height: '21.3%',
        transition: 'top 1.5s ease-in-out',
      },
    },
    Person1: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'none',
      },
    },
    Person2: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'none',
      },
    },
    AppLogo: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 1.5s ease-int-out',
      },
    },
    ButtonsContainer1: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1s ease-in-out 0s',
      },
    },
    ButtonsContainer2: {
      style: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'all 1s ease-in-out 0s',
      },
    },
    PreviewPointer1: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'none',
      },
    },
    PreviewPointer2: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'none',
      },
    },
    PreviewDescription1: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'none',
      },
    },
    PreviewDescription2: {
      animate: false,
      style: {
        opacity: 0,
        transition: 'none',
      },
    },
    PreviewPrintSamples: {
      style: {
        zIndex: 20,
        transform: 'scale(1)',
        filter: 'brightness(1) blur(0)',
        transition: 'none',
      },
    },
    PreviewEvents: {
      style: {
        zIndex: 20,
        transform: 'scale(1)',
        filter: 'brightness(1) blur(0)',
        transition: 'none',
      },
    },
    PreviewXsysTv: {
      style: {
        zIndex: 0,
        transform: 'scale(1)',
        filter: 'brightness(1) blur(0)',
        transition: 'none',
      },
    },
    PreviewWebinars: {
      style: {
        zIndex: 0,
        transform: 'scale(1)',
        filter: 'brightness(1) blur(0)',
        transition: 'none',
      },
    },
    PreviewTechnicalRoom: {
      style: {
        zIndex: 10,
        transform: 'scale(1)',
        filter: 'brightness(1) blur(0)',
        transition: 'none',
      },
    },
    ButtonStart: {
      style: {
        opacity: 0,
        transition: 'opacity 1.5 ease-in-out 0s',
      },
    },
  },
  // Skip: remove styles
  {
    Welcome: {
      style: {
        display: 'none',
      },
    },
    Triangle: {
      style: {
        display: 'none',
      },
    },
    Person1: {
      style: {
        display: 'none',
      },
    },
    Person2: {
      style: {
        display: 'none',
      },
    },
    AppLogo: {
      style: {
        display: 'none',
      },
    },
    ButtonsContainer1: {
      style: {
        display: 'none',
      },
    },
    ButtonsContainer2: {
      style: {
        display: 'none',
      },
    },
    PreviewPointer1: {
      style: {
        display: 'none',
      },
    },
    PreviewPointer2: {
      style: {
        display: 'none',
      },
    },
    PreviewDescription1: {
      style: {
        display: 'none',
      },
    },
    PreviewDescription2: {
      style: {
        display: 'none',
      },
    },
    PreviewPrintSamples: {
      style: null,
    },
    PreviewEvents: {
      style: null,
    },
    PreviewXsysTv: {
      style: null,
    },
    PreviewWebinars: {
      style: null,
    },
    PreviewTechnicalRoom: {
      style: null,
    },
    ButtonStart: {
      style: {
        opacity: 0,
        transition: 'opacity 1.5 ease-in-out 0s',
      },
    },
  },
];
