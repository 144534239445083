export default [
  // initial
  {
    Preview: {
      style: {
        bottom: '1.4vw',
      },
    },
    PrintSamplesCards: {
      style: {
        bottom: '20vw',
      },
    },
    LabelPrint: {
      style: {
        top: '27vw',
      },
    },
    LabelSamples: {
      style: {
        top: '37vw',
      },
    },
  },
  // scale
  {
    Preview: {
      style: {
        bottom: '-100%',
        transition: 'all 2s ease-in-out',
      },
    },
    PrintSamplesCards: {
      mode: 'scale',
      style: {
        bottom: '50%',
        transition: 'all 2s ease-in-out',
      },
    },
    LabelPrint: {
      style: {
        top: '18.85vw',
        transition: 'top 2s ease-in-out',
      },
    },
    LabelSamples: {
      style: {
        top: '28.9vw',
        transition: 'top 2s ease-in-out',
      },
    },
  },
  // squeeze
  {
    PrintSamplesCards: {
      mode: 'squeeze',
      style: {
        transition: 'all 2s ease-in-out',
      },
    },
    LabelPrint: {
      style: {
        top: '18.85vw',
        transform: 'translateX(-47%)',
        transition: 'all 0.5s ease-in-out',
      },
    },
    LabelSamples: {
      style: {
        top: '28.9vw',
        transform: 'translateX(-52%)',
        transition: 'all 0.5s ease-in-out',
      },
    },
  },
  // expand
  {
    PrintSamplesCards: {
      mode: 'expand',
      style: {
        transition: 'all 2s ease-in-out',
      },
    },
    LabelPrint: {
      style: {
        top: '38%',
        transform: 'translate(-72%, -50%)',
        transition: 'all 2s ease-in-out',
      },
    },
    LabelSamples: {
      style: {
        top: '59%',
        transform: 'translate(-34.5%, -50%)',
        transition: 'all 2s ease-in-out',
      },
    },
  },
];
