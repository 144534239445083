<template>
  <div data-vue-component-name="TheToolsButton">
    <AnimateGears />
    <h2>tools</h2>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/styles/pages/home/preview.scss";

[data-vue-component-name="TheToolsButton"] {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  [data-vue-component-name="AnimateGears"] {
    margin-right: 10%;
  }

  h2 {
    letter-spacing: 40px;
    font-size: 1.3vw;
    @include labelStyles;
  }
}
</style>
