import { request, endpoints } from '@xsys2/functions';

export default {
  async getHomeData({ commit }) {
    commit('SET_HOME_DATA', await request(endpoints('HOME')));
  },
  async getTutorialData({ commit }) {
    commit('SET_TUTORIAL_DATA', await request(endpoints('TUTORIAL')));
  },
};
