<template>
  <div data-vue-component-name="App">
    <!-- menu -->
    <AppWrapper
      id="menu-wrapper"
      blur="3"
      :bg-color="isMenuOpened
        ? 'rgba(0, 0, 0, 0.85)'
        : 'rgba(0, 0, 0, 1)'
      "
      :class="{ opened: isMenuOpened }"
    >
      <TheMenuButton
        :class="{ opened: isMenuOpened }"
        @click="toggleMenu(true)"
      />

      <TheMenu
        title="Virtual Sales"
        :items="menuItems"
        :active-item="activeMenuItem"
        :link="resolveLink"
        :class="{ opened: isMenuOpened }"
        @navigate="onNavigate"
      />
    </AppWrapper>

    <!-- menu close btn -->
    <button
      id="close-menu-btn"
      :class="{ opened: isMenuOpened }"
      @click="toggleMenu(false)"
    >
      <IconClose />
    </button>

    <!-- service wrapper -->
    <transition name="fade">
      <AppWrapper
        v-if="ServiceWrapperProps"
        id="service-wrapper"
        v-bind="ServiceWrapperProps"
      />
    </transition>

    <main>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>

    <OrientationLock />
  </div>
</template>

<script setup>
// dependencies
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// components
import {
  AppWrapper,
  TheMenu,
  TheMenuButton,
  IconClose,
} from '@xsys2/components';

const router = useRouter();
const store = useStore();

// menu
store.dispatch('getMenuItemsDescriptions');
const isMenuOpened = ref(false);
const toggleMenu = payload => (isMenuOpened.value = payload);
const menuItems = computed(() => store.state.common.menuItems);
const activeMenuItem = computed(() => {
  const currentPath = router.currentRoute.value.fullPath;

  for (const key in menuItems.value) {
    if (menuItems.value[key]?.path === currentPath) {
      return menuItems.value[key];
    }
  }
});
const resolveLink = computed(() => process.env.VUE_APP_XSYS_GLOBAL_URL);
const onNavigate = async (item) => {
  const resolvedRoute = router.resolve(item.path);

  if (resolvedRoute.matched.length) {
    await router.push(resolvedRoute);
    isMenuOpened.value = false;
  }
};

// Service Wrapper
const ServiceWrapperProps = computed(() => store.state.ui.ServiceWrapperProps);
</script>

<style scoped lang="scss">
[data-vue-component-name="App"] {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  #menu-wrapper {
    position: absolute;
    z-index: 30;
    top: 2vw;
    right: 32px;
    width: 6.3vw;
    height: 2.8vw;
    transition:
      width 1s ease-in-out 0s,
      height 1s ease-in-out 1s,
      top 1s ease-in-out 1s,
      right 1s ease-in-out 1s,
      background-color 1s ease-in-out 0s;

    &.opened {
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      transition:
        height 1s ease-in-out 0s,
        width 1s ease-in-out 1s,
        top 1s ease-in-out 0s,
        right 1s ease-in-out 0s,
        background-color 1s ease-in-out 1s;
    }

    [data-vue-component-name="TheMenuButton"] {
      opacity: 1;
      transition: opacity 1s ease-in-out 1s;

      &.opened {
        opacity: 0;
        pointer-events: none;
      }
    }

    [data-vue-component-name="TheMenu"] {
      position: absolute;
      top: 0;
      right: -32px;
      height: 100vh;
      width: 440px;
      opacity: 0.6;
      transform: translateX(100%);
      transition: all 1s ease-in-out 0s;

      &.opened {
        opacity: 1;
        transform: translateX(0);
        transition: all 1s ease-in-out 1s;
        right: 0;
      }
    }
  }

  #close-menu-btn {
    position: absolute;
    z-index: 30;
    opacity: 0;
    pointer-events: none;
    padding: 0.25rem;
    top: 32px;
    right: 32px;
    transition: opacity 1s ease-in-out;

    &.opened {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 1s ease-in-out 1s;
    }
  }

  #service-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  main {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  [data-vue-component-name="App"] {
    #menu-wrapper {
      top: 36px;
      width: 125px;
      height: 56px;

        [data-vue-component-name="TheMenu"] {
          &.opened {
            width: 100%;
            right: 0;
          }
        }
    }
  }
}
</style>
