<template>
  <!-- eslint-disable max-len -->
  <svg
    data-vue-component-name="IconPercent"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5.5L5 19.5"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.5 9.5C7.88071 9.5 9 8.38071 9 7C9 5.61929 7.88071 4.5 6.5 4.5C5.11929 4.5 4 5.61929 4 7C4 8.38071 5.11929 9.5 6.5 9.5Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 20.5C18.8807 20.5 20 19.3807 20 18C20 16.6193 18.8807 15.5 17.5 15.5C16.1193 15.5 15 16.6193 15 18C15 19.3807 16.1193 20.5 17.5 20.5Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

</template>
