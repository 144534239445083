<template>
  <div
    data-vue-component-name="PrintSamplesCards"
    :class="mode"
  >
    <PrintSamplesCard
      v-for="(card, index) in cards"
      :key="index"
      :active-mode="mode"
      :card="card"
      :alt="`print sample image ${index + 1}`"
      :is-active-hover="!isNull(hoveredCardIndex) && hoveredCardIndex === index"
      :is-passive-hover="!isNull(hoveredCardIndex) && hoveredCardIndex !== index"
      @click="onCardClick(index)"
      @update-hover-status="updateHoveredCardIndex($event, index)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { isNull } from '@xsys2/functions';

const props = defineProps({
  mode: {
    type: String,
    default: null,
    validator: value => ['scale', 'squeeze', 'expand'].includes(value),
  },
  cards: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits({
  onCardClick: index => typeof index === 'number',
});

const hoveredCardIndex = ref(null);
const updateHoveredCardIndex = (isHovered, index) => {
  if (props.mode === 'expand') {
    hoveredCardIndex.value = isHovered
      ? index
      : null;
  }
};

const onCardClick = (index) => {
  if (props.mode === 'expand') {
    emits('onCardClick', index);
  }
};
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamplesCards"] {
  gap: 1.6vw;
  width: 31.759vw;
  height: 20.916vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  transition: all 2s ease-in-out;

  &.scale,
  &.squeeze {
    width: 48.214vw;
    height: 31.771vw;
    gap: 2.604vw;
  }

  &.expand {
    width: 95vw;
    height: 40vw;
  }
}
</style>
