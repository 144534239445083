<template>
  <component
    :is="tag"
    data-vue-component-name="AppHover"
    @mouseenter="toggleIsHovered(true)"
    @mouseleave="toggleIsHovered(false)"
  >
    <slot :is-hovered="isHovered" />
  </component>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  tag: {
    type: String,
    default: 'div',
  },
});

const isHovered = ref(false);
const toggleIsHovered = (payload) => {
  isHovered.value = payload;
};
</script>
