<template>
  <div
    data-vue-component-name="EventNumber"
    :class="{ visible: isVisible }"
  >
    <hr>
    <span>{{ transformNumber }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  number: {
    type: Number,
    default: null,
  },
});

const transformNumber = computed(
  () => (props.number.toString().length < 2 ? `0${props.number}` : props.number),
);
</script>

<style scoped lang="scss">
$primary: map-get($theme-colors, "primary");

[data-vue-component-name="EventNumber"] {
  position: relative;
  width: 0;

  hr {
    border: none;
    width: 0;
    height: 100%;
    transition: all 1s ease-in-out;
    opacity: 0;
    background-color: $primary;
  }

  span {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    transition: all 1s ease-in-out;
    font-size: 1.25vw;
    line-height: 150%;
    font-weight: 200;
    letter-spacing: 1px;
    pointer-events: none;
  }

  &.visible {
    hr {
      width: 0.42vw;
      opacity: 1;
    }

    span {
      opacity: 1;
      transform: translate(75%, -50%);
    }
  }
}
</style>
