export default function listenSize(node, handler) {
  onNodeLoaded();
  onNodeResized();

  function onNodeLoaded() {
    node.addEventListener('load', updateSize, { once: true });
  }

  function onNodeResized() {
    window.addEventListener('resize', updateSize, { passive: true });
  }

  function updateSize() {
    handler({
      height: node.height,
      width: node.width,
    });
  }
}
