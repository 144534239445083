<template>
  <button
    data-vue-component-name="TheInfoButton"
    :class="{ opened: modelValue }"
    @click="toggle"
  >
    <IconInfo />

    <div>
      <span>Close</span>
      <IconClose />
    </div>
  </button>
</template>

<script setup>
import { IconInfo, IconClose } from '@xsys2/components';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits({
  'update:modelValue': value => typeof value === 'boolean',
});

const toggle = () => emits('update:modelValue', !props.modelValue);
</script>

<style scoped lang="scss">
[data-vue-component-name="TheInfoButton"] {
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
  overflow: hidden;
  width: 2.8vw;
  height: 2.8vw;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &.opened {
    width: 5.7vw;

    [data-vue-component-name="IconInfo"] {
      opacity: 0;
    }

    div {
      opacity: 1;
    }
  }

  [data-vue-component-name="IconInfo"] {
    width: 1.2vw;
    height: 1.2vw;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease-in-out;
  }

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include flex-center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    span {
      color: #fff;
      font-size: 1vw;
    }

    [data-vue-component-name="IconClose"] {
      width: 1.2vw;
      height: 1.2vw;
      margin-left: .5vw;
    }
  }
}

@media screen and (max-width: 768px) {
  [data-vue-component-name="TheInfoButton"] {
    width: 56px;
    height: 56px;

    &.opened {
      width: 109px;
    }

    [data-vue-component-name="IconInfo"] {
      width: initial;
      height: initial;
    }

    div {

      span {
        font-size: initial;
      }

      [data-vue-component-name="IconClose"] {
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }
    }
  }
}
</style>
