<template>
  <transition name="fade" appear>
    <div
      v-if="isLocked"
      data-vue-component-name="OrientationLock"
    >
      <AppLogo />

      <div class="container">
        <IconOrientation />

        <p>
          Please rotate the device to landscape mode,
          or open the site from a desktop computer.
        </p>
      </div>

      <img
        src="backgrounds/Orientation.png"
        alt="orientation lock background"
      >
    </div>
  </transition>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { AppLogo } from '@xsys2/components';
import { metaController } from '@/modules';

// meta
// metaController({
//   link: [
//     {
//       rel: 'preload',
//       href: 'Orientation.png',
//       as: 'image',
//     },
//   ],
// });

const isLocked = ref(false);

function updateLockStatus() {
  const { availWidth, availHeight } = window.screen;
  const isPortrait = availHeight > availWidth;

  isLocked.value = isPortrait || availWidth < 1023;
}

onMounted(() => {
  updateLockStatus();
  window.addEventListener('orientationchange', updateLockStatus);
});
</script>

<style scoped lang="scss">
[data-vue-component-name="OrientationLock"] {
  @include absolute-full;
  @include flex-center;
  z-index: 80;
  background-color: #000;

  [data-vue-component-name="AppLogo"] {
    width: 160px;
    height: 80px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    width: 640px;
    color: #FFFFFF;

    [data-vue-component-name="IconOrientation"] {
      width: 120px;
      height: 120px;
      margin-bottom: 32px;
    }

    p {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      margin-bottom: 48px;
    }

    [data-vue-component-name="AppButton"] {
      padding: 24px 64px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  img {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: contain;
    width: 100%;
  }
}
</style>
