import { reactive } from 'vue';
import { toggleServiceWrapper } from '@/modules';
import { isTutorialCompleted, tutorialPlayer } from '@/modules/tutorial';

export default function tutorialController() {
  let next;
  let skip;
  const props = reactive({});

  const serviceWrapperProps = {
    bgColor: 'rgba(0, 0, 0, 0.85)',
    blur: '8',
    style: {
      zIndex: 30,
    },
  };

  if (!isTutorialCompleted.value) {
    toggleServiceWrapper(serviceWrapperProps);
    ({ next, skip } = tutorialPlayer(props, onTutorialEnds));
  }

  function onTutorialEnds() {
    isTutorialCompleted.value = true;
    toggleServiceWrapper(null);
  }

  return {
    props,
    next,
    skip,
  };
}
