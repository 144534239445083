<template>
  <!-- eslint-disable max-len -->
  <svg
    data-vue-component-name="LabelPrint"
    width="430"
    height="103"
    viewBox="0 0 430 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.25"
      d="M74.7109 32.8984C74.7109 44.3828 71.3125 53.2656 64.5156 59.5469C57.7656 65.7812 48.1562 68.8984 35.6875 68.8984H27.8828V103H0.109375V0.203125H35.6875C48.6719 0.203125 58.4219 3.03906 64.9375 8.71094C71.4531 14.3828 74.7109 22.4453 74.7109 32.8984ZM27.8828 46.2578H32.9453C37.1172 46.2578 40.4219 45.0859 42.8594 42.7422C45.3438 40.3984 46.5859 37.1641 46.5859 33.0391C46.5859 26.1016 42.7422 22.6328 35.0547 22.6328H27.8828V46.2578ZM118.867 65.5938V103H91.0938V0.203125H124.773C152.711 0.203125 166.68 10.3281 166.68 30.5781C166.68 42.4844 160.867 51.6953 149.242 58.2109L179.195 103H147.695L125.898 65.5938H118.867ZM118.867 44.7109H124.07C133.773 44.7109 138.625 40.4219 138.625 31.8438C138.625 24.7656 133.867 21.2266 124.352 21.2266H118.867V44.7109ZM188.547 103V0.203125H216.461V103H188.547ZM336.625 103H300.203L262.656 30.5781H262.023C262.914 41.9688 263.359 50.6641 263.359 56.6641V103H238.75V0.203125H275.031L312.438 71.6406H312.859C312.203 61.2812 311.875 52.9609 311.875 46.6797V0.203125H336.625V103ZM404.195 103H376.422V22.9141H351.32V0.203125H429.227V22.9141H404.195V103Z"
      fill="white"
    />
  </svg>
</template>
